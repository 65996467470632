.ui-check{
  position: relative;
  padding-left: 20px;
  cursor: pointer;
  input{
    opacity: 0;
    position: absolute;
    z-index: -1;
    &:checked + i{
      &:before{
        left: ($ui-check-size - $ui-check-sign-size)/2;
        top: ($ui-check-size - $ui-check-sign-size)/2;
        width: $ui-check-sign-size;
        height: $ui-check-sign-size;
        background-color: $ui-check-sign-color;
      }
    }
    &:checked + span .active{
      display: inherit;
    }
    &[type="radio"] + i{
      &,
      &:before{
        border-radius: 50%;
      }
    }
    &[type="checkbox"]:checked + i:before{
      
    }
    &[type="radio"]:checked + i:before{
      
    }
    &[disabled],
    fieldset[disabled] & {
      & + i{
        border-color: lighten($input-border, 5%);
        &:before{
          background-color: lighten($input-border, 5%);
        }
      }
    }
  }
  > i{
    width: $ui-check-size;
    height: $ui-check-size;
    line-height: 1;
    box-shadow: 0 0 1px rgba(120,130,140, 0.35);
    margin-left: -20px;
    margin-top: -2px;
    display: inline-block;
    vertical-align: middle;
    margin-right: 4px;
    background-clip: padding-box;
    position: relative;
    &:before{
      content:"";
      position: absolute;
      left: 50%;
      top: 50%;
      width: 0px;
      height: 0px;
      background-color: transparent;
    }
  }
  > span{
    margin-left: -20px;
    .active{
      display: none;
    }
  }
  &.ui-check-color input:checked + i:before {
    background-color: #fff;
  }
}

.ui-check-md{
  input{
    &:checked + i{
      &:before{
        left: ($ui-check-md-size - $ui-check-sign-size)/2;
        top: ($ui-check-md-size - $ui-check-sign-size)/2;
      }
    }
  }
  > i{
    width: $ui-check-md-size;
    height: $ui-check-md-size;
  }
}

.ui-check-lg{
  input{
    &:checked + i{
      &:before{
        width: $ui-check-sign-size * 2;
        height: $ui-check-sign-size * 2;
        left: ($ui-check-lg-size - $ui-check-sign-size * 2)/2;
        top: ($ui-check-lg-size - $ui-check-sign-size * 2)/2;
      }
    }
  }
  > i{
    width: $ui-check-lg-size;
    height: $ui-check-lg-size;
  }
}
