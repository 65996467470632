.pagination,
.pager{
  .page-item,
  > li > a,
  > li > span{
    color: inherit !important;
    background-color: transparent !important;
    border-color: $border-color !important;
    &:hover,
    &:focus{
      border-color: $border-color;
      background-color: $min-black;
      color: inherit;
    }
  }
  .page-item.active,
  > .active > a,
  > .active > span{
    color: $full-white !important;
    background-color: $primary !important;
    border-color: $primary !important;
  }
}

.pagination{
  > li{
    display: inline;
    list-style: none;
  }
  > li > a{
    position: relative;
    float: left; // Collapse white-space
    padding: $pagination-padding-y $pagination-padding-x;
    margin-left: -1px;
    line-height: $line-height;
    color: $pagination-color;
    text-decoration: none;
    background-color: $pagination-bg;
    border: $pagination-border-width solid $pagination-border-color;

    @include hover-focus {
      color: $pagination-hover-color;
      background-color: $pagination-hover-bg;
      border-color: $pagination-hover-border;
    }
  }
}
