﻿body {   
    /* fixes a weird issue with dropdowns */
    .dropdown-menu {   
        display:none;
    } 
    .open > .dropdown-menu {      
        display:block;
    } 

    #invisible {
        iframe {
            visibility: hidden;
            position: absolute;
            left: 0; top: 0;
            height:0; width:0;
            border: none;
        }
    }
    
    #nymir-backdrop {
        background-image: url('/dist/assets/images/nymir-bg.png');
        background-position: left;
        background-size: contain;
        background-repeat: no-repeat;
        width: 100%;
        height: 100%; 
        top: 0; 
        left: 0; 
        position: fixed; 
    }
    
    .navbar-nav li.loader {
        margin: 12px 24px 0 6px;
    }


    /* animate page transitions */
    .au-enter-active {
        -webkit-animation: fadeInRight 1s;
        animation: fadeInRight 1s;
    }

    .au-stagger {
        /* 50ms will be applied between each successive enter operation */
        -webkit-animation-delay: 50ms;
        animation-delay: 50ms;
    }

    .au-enter {
        opacity: 0;
    }

    .au-enter-active {
        -webkit-animation: fadeIn 2s;
        animation: fadeIn 2s;
    }

    /* animation definitions */
    @-webkit-keyframes fadeInRight {
        0% {
            opacity: 0;
            -webkit-transform: translate3d(100%, 0, 0);
            transform: translate3d(100%, 0, 0);
        }

        100% {
            opacity: 1;
            -webkit-transform: none;
            transform: none;
        }
    }

    @keyframes fadeInRight {
        0% {
            opacity: 0;
            -webkit-transform: translate3d(100%, 0, 0);
            -ms-transform: translate3d(100%, 0, 0);
            transform: translate3d(100%, 0, 0);
        }

        100% {
            opacity: 1;
            -webkit-transform: none;
            -ms-transform: none;
            transform: none;
        }
    }

    @-webkit-keyframes fadeIn {
        0% {
            opacity: 0;
        }

        100% {
            opacity: 1;
        }
    }

    @keyframes fadeIn {
        0% {
            opacity: 0;
        }

        100% {
            opacity: 1;
        }
    }    
    
    &.ieLessThanTen {
        .k-grid-pdf {
            display: none;
        }
    }

    .k-grid .k-grid-header .k-header .k-link {
        height: auto;
    }

    .k-grid .k-grid-header .k-header {
        white-space: normal;
    }
 
    .folded .navbar {
        text-align: left;
    }

    .nav-active-primary .nav li.nymir-nav-active > a {
        background-color: #01A9B6 !important;
    }

    .nav-active-primary .nav .nymir-sub-active > a {
        background-color: #01A9B6 !important;
    }

    #policy-menu {
        .nav-link:not(.active) {
            &:hover {
                //border-radius:0;
                //border-left:2px solid #01A9B6;
                //padding-left:0.625rem;
                background-color: #eee;
            }
        }
    }

    .app-header {
        #pageTitle {
            font-weight: 900;
        }
    }

    #insured-details {
        .text-2x {
            color: #666;
        }
    }

    #insured-links {
        @include media-breakpoint-up(xl) {
            text-align: right;

            a {
                margin-right: 15px;
            }
        }
    }

    #policy-meta-details {
        i.fa {
            font-size: 2.5em;
        }

        text-align: center;
        
        @media (max-width:1410px) and (min-width:1200px) {
            i.fa {
                margin-top:8px;
                font-size: 3em !important;          
            }
        }        

        @include media-breakpoint-up(lg) {
            text-align: left;

            i.fa {
                font-size: 4em;
                color: $primary;
            }
        }

        h5 {
            margin-bottom: 0;

            a {
                font-size: 1.4em;
                font-weight: 400;
            }

            .label {
                background-color: #942E53;
                color: white;
                text-transform: uppercase;
                font-weight: 400;
                vertical-align: text-top;
            }
        }
    }

    #policy-dates {
        text-align: center;

        @include media-breakpoint-up(lg) {
            text-align: right;
        }

        i.material-icons {
            color: #333;
            font-size: 1.8em;
            vertical-align: bottom;
        }

        span {
            color: #333;
            font-size: 1.4em;
            font-weight: 400;
        }
    }

    .header-subtle {
        a {
            i.material-icons {
                color: #ccc;
            }

            span {
                color: #333;
                font-size: 1.4em;
            }

            &:hover {
                i.material-icons {
                    color: #222;
                }

                span {
                    color: #222;
                }
            }
        }
    }

    .red-nymer {
        color: #942E53;
    }

    .k-grid-toolbar, .k-grid-pdf, .k-grid-excel {
        background-color: #01A9B6 !important;
        border-color: #01A9B6 !important;
    }

    .header-space {
        letter-spacing: .5px;
    }

    .box-footer {
        padding: 1rem 0 0 0 !important;
    }

    .footer-button {
        border-radius: 0 0 0 0 !important;
    }

    .third-green {
        color: #2FD5E1;
    }

    .grey-50 {
        h4 {
            font-weight: 900;
            font-size: 1.2em;
            color: #3C3C3C;
        }
    }

    .row-eq-height {
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
    }

    .col-center {
        float: none;
        margin-right: auto;
        margin-left: auto;
    }

    @include media-breakpoint-down(md) {
        .app-aside {
            .left {
                width: unset;

                .nav > li > a .nav-text {
                    display: block;
                    margin-top: -18px;
                    font-size: 0.85em;
                }

                .nav > li > a .nav-icon {
                    float: none;
                    line-height: 2.5rem;
                    margin-left: 0;
                    margin-right: 0;
                    position: relative;
                }
            }
        }
    }

    table.table {
        thead tr {
            //background-color: #fafafa;

            th {
                //color:#a8a8a8;
                //font-weight:normal;
            }
        }

        tfoot tr {
            background-color: #fafafa;

            td {
                color: #444;
            }
        }
    }

    #accordion.insured-contacts {
        .panel-title a {
            display: block;
            padding: 1rem;
        }

        .box-header {
            padding: 0;
        }
    }
}

@page {
  //size: 5.5in 8.5in;  
  margin: 20pt 20pt 20pt 20pt;
}

@media print {   

    html > body { 
        #aside, #policy-nav-wrapper, #insured-links, #commission, #print-button {
            display:none !important;
        }    

        #insured-details {
            text-align:center;
            width:100%;
        }

        .app-header ~ .app-body {
            padding-top:0;
        }

        .app-aside.folded.md:not(.hide) ~ .au-target .app-content {
            margin-left:0;
        }

        #policy-content {
            display:block;
            width:100%;
            height:100%;
            padding:0;
            margin:0;

            section {
                -webkit-print-color-adjust: exact;

                .box {
                    page-break-after:avoid;
                    page-break-before:avoid;
                    page-break-inside:avoid;
                }
            }            
        }
    }
}
