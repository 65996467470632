﻿@include media-breakpoint-up(sm) {
    #insured-policies {
        &.box {
            .box-body {
                padding:25px 25px 0 25px;
            }
        }
    } 

    .box, modal-body {
        .form-group {
            //margin-bottom:0;

            .form-control-label {
                font-weight:bold;

                &:after {
                    content: ":"
                }
            }
        }    
    }    
}

