﻿.k-calendar-container {
    .k-calendar {
        .k-header {
            background-color: $grey;
        }

        td.k-state-selected {
            background-color: $grey;

            &:hover {
                background-color: $grey;
            }
        }
    }
}