html {
  background-color: $html-bg;
}
body {
  color: $text-color;
  font-size: $font-size;
  background-color: $body-bg;
  -webkit-font-smoothing: antialiased;
}

*:focus {
  outline: 0 !important;
}

a {
  color: $link-color;
  text-decoration: none;
  cursor: pointer;
  outline: 0;
}
a:hover,
a:focus {
  color: $hover-color;
  text-decoration: none;
}

pre {
  color: inherit;
  background-color: $light;
  border-color: $border-color;
}

blockquote{
  border-color: $border-color;
}

small{
  font-size: 90%;
}

sup{
  position: relative; 
  top: -1em; 
  font-size: 75%
}

.pull-center{
  position: absolute;
  left: 50%;
}

.close{
  font-size: 1.3rem;
}

.img-responsive{
  width: 100%;
  height: auto;
}

.text-center{
  text-align: center;
}

.text-left{
  text-align: left;
}

.text-right{
  text-align: right;
}

.pull-left{
  float: left;
}

.pull-right{
  float: right;
}

@include media-breakpoint-down(sm){
  .pull-none-sm{
    float: none !important;
  }
}

@include media-breakpoint-down(xs){
  .pull-none-xs{
    float: none !important;
  }
}
