﻿p.aurelia-validation-message {
    display: inline;
    margin-left: 5px;

    &.help-block {
        color: #f44455;
        font-weight:bold;
    }

    &:empty {
        display: none;
    }
}

.has-success {
    label {
        //color: #3c763d;
    }

    .form-control {
         border-color: rgba(120, 130, 140, 0.2);
    }
}

.has-warning {
    label {
        color: #f44455;
    }

    .form-control {
         border-color: #f44455;
    }
}

