.dropdown-menu{
  font-size: 0.875rem;
  color: $dark-black;
  border-radius: $border-radius-base;
  border: 1px solid rgba(0, 0, 0, 0.1);
  @include transform-origin(top left);

  > li:not(.dropdown-item) > a{
    display: block;
    padding: 3px 20px;
    &:hover,
    &:focus{
      background-color: $min-black;
    }
  }
  > .divider{
    height: 1px;
    margin: .5rem 0;
    overflow: hidden;
    background-color: $border-color;
  }

  .active{
    background-color: $min-black;
  }
  &.pull-left{
    left: auto !important;
    right: 100%;
  }
  &.pull-right{
    left: auto;
    right: 0;
    @include transform-origin(top right);
  }
  &.pull-up{
    top: 0;
  }
  &.pull-down{
    top: 100% !important;
    left: 0 !important;
  }
  .dropup & {
    &{
      @include transform-origin(bottom left);
      &.pull-right{
        @include transform-origin(bottom right);
      }
    }
  }

  &.datepicker,
  &.timepicker{
    padding: 6px;
    font-size: 0.8rem;
    .btn{
      font-size: 0.8rem;
      &.btn-primary{
        background-color: $info;
        color: #fff;
      }
    }
    .btn:not(:hover){
      box-shadow: 0 0 1px rgba(0,0,0,0);
    }
  }
}

.dropdown-item{
  color: inherit;
  &:hover,
  &:focus{
    color: inherit;
    background-color: $min-black;
  }
}

.dropdown-divider{
  background-color: $border-color;
}

.dropdown-menu-scale{
  display: block;
  opacity: 0;
  @include scale(0.8);
  @include transition(all 250ms cubic-bezier(0.24,0.22,0.015,1.56));
  @include backface-visibility(hidden);
  pointer-events: none;
}

.open > .dropdown-menu-scale{
  opacity: 1;
  @include scale(1);
  pointer-events: auto;
  display: block !important;
}

.dropdown-header{
  padding: 8px 16px;
}

.dropdown-submenu{
  position: relative;
  &:hover,
  &:focus{
    > .dropdown-menu {
      display: block;
    }
  }
  .dropdown-menu{
    left: 100%;
    top: 0;
    margin-top: -6px;
    margin-left: -1px;
  }
  .dropup & {
    > .dropdown-menu{
      top: auto;
      bottom: 0;
      margin-bottom: -6px;
    }
  }
}

.popover{
  color: $dark-black;
}

@include media-breakpoint-down(xs) {
  .dropdown-menu.pull-none-xs{left: 0;}
}
